import { StatusStateEnum } from "~/types/enums";
const insightReportSelectors = {
  getReports: _ref => {
    let {
      insightReports: r
    } = _ref;
    return r.ids.map(key => r.entities[key]);
  },
  getHistoryReports: _ref2 => {
    let {
      insightReports: r
    } = _ref2;
    return r.history;
  },
  getReport: reportId => _ref3 => {
    let {
      insightReports: r
    } = _ref3;
    return r.entities[reportId];
  },
  getAssistantResponse: reportId => _ref4 => {
    let {
      insightReports: r
    } = _ref4;
    return r.assistantResponse[reportId];
  },
  getSelected: _ref5 => {
    let {
      insightReports: r
    } = _ref5;
    return r.selectedId ? r.entities[r.selectedId] : null;
  },
  getSelectedData: _ref6 => {
    let {
      insightReports: r
    } = _ref6;
    return r.selectedData;
  },
  getAction: _ref7 => {
    let {
      insightReports: r
    } = _ref7;
    return r.action;
  },
  getHistoryStatus: _ref8 => {
    let {
      insightReports: r
    } = _ref8;
    return r.status.history;
  },
  getStatusByReport: reportId => _ref9 => {
    let {
      insightReports: r
    } = _ref9;
    return r.status.byReport[reportId] || StatusStateEnum.IDLE;
  },
  getStatusByGenerateReport: reportId => _ref10 => {
    let {
      insightReports: r
    } = _ref10;
    return r.status.byReportGenerate[reportId] || StatusStateEnum.IDLE;
  },
  getStatusBySendReport: reportId => _ref11 => {
    let {
      insightReports: r
    } = _ref11;
    return r.status.byReportSend[reportId] || StatusStateEnum.IDLE;
  },
  getStatusByHighlightDismiss: reportId => _ref12 => {
    let {
      insightReports: r
    } = _ref12;
    return r.status.byHighlightDismiss[reportId] || StatusStateEnum.IDLE;
  },
  getStatusByFindingRate: reportId => _ref13 => {
    let {
      insightReports: r
    } = _ref13;
    return r.status.byFindingRate[reportId] || StatusStateEnum.IDLE;
  },
  getHistoryError: _ref14 => {
    let {
      insightReports: r
    } = _ref14;
    return r.error.history;
  },
  getAssistantQueryStatus: reportId => _ref15 => {
    let {
      insightReports: r
    } = _ref15;
    return r.status.byAssistantQuery[reportId] || StatusStateEnum.IDLE;
  },
  getErrorByReport: reportId => _ref16 => {
    let {
      insightReports: r
    } = _ref16;
    return r.error.byReport[reportId];
  },
  getErrorByGenerateReport: reportId => _ref17 => {
    let {
      insightReports: r
    } = _ref17;
    return r.error.byReportGenerate[reportId];
  },
  getErrorBySendReport: reportId => _ref18 => {
    let {
      insightReports: r
    } = _ref18;
    return r.error.byReportSend[reportId];
  },
  getErrorByHighlightDismiss: reportId => _ref19 => {
    let {
      insightReports: r
    } = _ref19;
    return r.error.byHighlightDismiss[reportId];
  },
  getErrorByFindingRate: reportId => _ref20 => {
    let {
      insightReports: r
    } = _ref20;
    return r.error.byFindingRate[reportId];
  },
  getAssistantQueryError: reportId => _ref21 => {
    let {
      insightReports: r
    } = _ref21;
    return r.error.byAssistantQuery[reportId];
  }
};
export default insightReportSelectors;